import React, { useEffect, useState } from "react";
// import gameIcon from "../images/icons/game-icon.svg";
import gameIcon from "../images/icon/game-icon.svg";
import superOver2 from "../images/gameImg/superover2.jpg";
import teen41 from "../images/gameImg/teen41.jpg";
import { useNavigate, useParams } from "react-router-dom";
import MatchDetail from "./MatchDetail";


const GameDataTable = ({ data }) => {
  const navigate = useNavigate();
  



  const gamesData = [
    {
      gameImage: require("../images/gameImg/superover2.jpg"),
      gameName: "Super Over 2",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/sicbo2.jpg"),
      gameName: "Sicbo 2",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/superover2.jpg"),
      gameName: "Super Over 2",
    },

    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
    {
      gameImage: require("../images/gameImg/teen41.jpg"),
      gameName: "teen 41",
    },
  ];

  return (
    <div>
      <div className="original_main_body">
        <div className="head_main">
          <div className="head_left">Game</div>
          <div className="head_right">
            <div>1</div>
            <div>x</div>
            <div>2</div>
          </div>
        </div>

        {data &&
          data?.map((item, i) => {
            return (
              <MatchDetail match={item} key={item.eventId} />
            );
          })}

        <div className="game_image_main">
          {gamesData &&
            gamesData?.map((item) => {
              return (
                <div className="single_game_main">
                  <div>
                    <img src={item?.gameImage} alt="" className="game_image" />
                  </div>
                  <h5>{item?.gameName}</h5>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default GameDataTable;
