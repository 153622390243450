import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";
import "./style.css"
// import "./modal/Modal.css"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "./context/AuthProvider";

function App() {
  return (
    <React.Fragment>
      <AuthProvider>
        <div className="App">
          <BrowserRouter>
            <AppRoutes />
            <ToastContainer theme="light" />
          </BrowserRouter>
        </div>
      </AuthProvider>
    </React.Fragment>
  );
}

export default App;
