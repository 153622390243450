import React from 'react'
import { useState,useEffect } from 'react';

function MarketBox({ odd, volume, color="" ,onClick}) {
    const [blink, setBlink] = useState(false);

    useEffect(() => {
        if (odd || volume) {
            setBlink(true);  // Set blink state to true on change
            const timeout = setTimeout(() => {
                setBlink(false);  // Reset blink state after animation
            }, 1000); // Duration of the blink effect
            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [odd, volume]);  // Watch changes for odd and volume

    return (
        <div className={`body_right market-odd-box ${color} ${blink ? 'blink' : ''}`} onClick={onClick}>
            <span className="market-odd">{odd}</span>
            <span className="market-volume">{volume}</span>

        </div>
    )
}

export default MarketBox