import React, { useEffect, useMemo, useState } from "react";
import { requestHandler } from "../../utils/requestHandler";
import EditButtonModal from "../../modal/EditButtonModal";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import MarketBox from "../../components/MarketBox";
import { BET_TYPE, BetDetails } from "../../utils/utils";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthProvider";
import { bookmakeBackProfit, bookmakeLayProfit, matchoddsBackProfit, matchoddsLayProfit } from "../../utils/formulas";
const SOCKET_URL = "http://134.209.148.155:5000";

const GameDetails = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [showPlaceBet, setPlaceBet] = useState(false);
  const [changeColor, setChangeColor] = useState("");
  const [betButtonsData, setBetButtonsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const queryParams = useParams()

  const [socket, setSocket] = useState(null);
  const [matchOdds, setMatchOdds] = useState([])
  const [bookmaker, setBookmaker] = useState([])
  const [othermarket, setOtherMarket] = useState([])
  const [gameDetails, setGameDetails] = useState(null)
  const [gameOdds, setGameOdds] = useState()
  const [fancyOdds, setFancyOdds] = useState([])
  const [betDetail, setBetDetail] = useState(null)
  const { betPlaced, fetchMe } = useAuth()
  const openPlaceBet = (color) => {
    setPlaceBet(true);
    setChangeColor(color);
  };


  const getGameDetails = () => {
    const type = queryParams.type
    requestHandler({
      endpoint: `/${type}/detail/${queryParams.id}`,
      method: "GET"

    }).then((res) => {
      setGameDetails(res.data)

    }).catch(err => {

    })
  }

  const getGameOdds = () => {
    const type = queryParams.type

    if (type === "cricket") {
      requestHandler({
        endpoint: `/cricket/odds/${queryParams.id}`,
        method: "GET"

      }).then((res) => {
        setMatchOdds(res.data.data?.matchOdds || [])
        setBookmaker(res.data?.data?.bookMakerOdds || [])
        setOtherMarket(res.data.data?.otherMarketOdds || [])
        setFancyOdds(res.data.data?.fancyOdds || [])
      }).catch(err => {

      })
    }
    if (type === "soccer") {
      requestHandler({
        endpoint: `/soccer/odds/${queryParams.id}`,
        method: "GET"

      }).then((res) => {
        setMatchOdds(res.data.data?.matchOddsResponseDTO || [])
        setBookmaker(res.data?.data?.bookMakerOddsResponseDTO || [])
        setOtherMarket(res.data.data?.other_market_odds || [])
        setFancyOdds(res.data.data?.fancyOdds || [])
      }).catch(err => {


      })
    }
    if (type === "tennis") {
      requestHandler({
        endpoint: `/tennis/odds/${queryParams.id}`,
        method: "GET"

      }).then((res) => {
        setMatchOdds(res.data.data?.matchOddsResponseDTO || [])
        setBookmaker(res.data?.data?.bookMakerOddsResponseDTO || [])
        setOtherMarket(res.data.data?.other_market_odds || [])
        setFancyOdds(res.data.data?.fancyOdds || [])
      }).catch(err => {

      })
    }

  }

  useEffect(() => {
    // Initial fetch
    getGameDetails();
    getGameOdds()

    // Polling every 5 seconds (5000 milliseconds)
    const intervalId = setInterval(() => {
      getGameOdds();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);




  const getButtonsData = async () => {
    let token = localStorage.getItem("token");

    try {
      let res = await requestHandler({
        endpoint: "/user/user-buttons",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res && res.success) {
        setBetButtonsData(res?.data?.[0]);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getButtonsData();
  }, []);

  const submitBet = () => {
    requestHandler({
      endpoint: "/user/bet",
      method: "POST",
      body: betDetail,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`

      }
    }).then((res) => {
      fetchMe()
      setBetDetail(null)
      toast.success(res.message)

    }).catch(err => {

    })
  }
  const getProfit = useMemo(() => {
    if(!betDetail?.stake) return 0
    if (betDetail?.type === BET_TYPE.MATCH_ODDS && betDetail?.backLay == "back") {
      return matchoddsBackProfit(betDetail?.odds, betDetail?.stake)
    }
    if (betDetail?.type === BET_TYPE.MATCH_ODDS && betDetail?.backLay == "lay") {
      return matchoddsLayProfit(betDetail?.odds, betDetail?.stake)
    }
    if (betDetail?.type === BET_TYPE.BOOKMAKER && betDetail?.backLay == "back") {
      return bookmakeBackProfit(betDetail?.odds, betDetail?.stake)
    }
    if (betDetail?.type === BET_TYPE.BOOKMAKER && betDetail?.backLay == "lay") {
      return bookmakeLayProfit(betDetail?.odds, betDetail?.stake)
    }
    return 0

  }, [betDetail])

  return (
    <div className="game_details_main">
      <div className="game_details_main_left">
        {/* 1st div */}
        <div className="game_details_header black_header">
          <p>{gameDetails?.eventName}</p>
          <p>{gameDetails?.eventTime}</p>

        </div>
        {/* 2nd div */}
        <div className="game_details_scoreboard">
          <div className="pitch_background">
            <div className="pitch_top">
              <p>LF</p>
              <p>115-9 (18.0)</p>
              <p>GL won the match</p>
            </div>
            <div className="pitch_bottom">
              <p>GL</p>
              <div className="two_run_over">
                <p>54-4 (10.5)</p>
                <div>
                  <p>CRR 48.9 RR</p>
                  <p>48.65</p>
                </div>
              </div>
              <div className="over_ball">
                <div className="circle_blue_ball">1</div>
                <div className="circle_blue_ball">1</div>
                <div className="circle_blue_ball">1lb</div>
                <div className="circle_blue_ball">1</div>
                <div className="circle_blue_ball">1</div>
                <div className="circle_blue_ball">1</div>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd bookmaker div */}

        {/* {data?.matchOdds[0]?.oddDatas?.length > 0 && */}
        {matchOdds.map((item) => {
          return <div className="book_maker_main" key={item.mid}>
            <div className="black_header">
              <p>{item.mname} </p>
            </div>
            <div className="bookmaker_body">
              <div className="bookmaker_body_single">
                <div className="bookmaker_body_left">
                  <p>Min - 100 </p>
                  <p>Max - 1L</p>
                </div>
                <div className="bookmaker_body_right">
                  <div className="body_right "></div>
                  <div className="body_right "></div>
                  <div className="body_right darkblue">Back</div>
                  <div className="body_right darkpink">Lay</div>
                  <div className="body_right"></div>
                  <div className="body_right"></div>
                </div>
              </div>
              {item.oddDatas.map((oddData) => {
                return <div className="bookmaker_body_single" key={oddData.sid}>
                  <div className="bookmaker_body_left">
                    <p>{oddData.rname}</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <MarketBox onClick={() => setBetDetail(new BetDetails({
                      marketId: `${gameDetails.eventId}_${oddData.sid}`,
                      odds: oddData.b3,
                      volume: oddData.bs3,
                      gameDetails: gameDetails,
                      sport: queryParams.type,
                      type: "match_odds",
                      backLay: "back",
                      betFor: oddData.rname
                    }))} color="lighterblue" odd={oddData.b3} volume={oddData.bs3} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b2,
                        volume: oddData.bs2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "match_odds",
                        backLay: "back",
                        betFor: oddData.rname

                      }))}
                      color="lightblue" odd={oddData.b2} volume={oddData.bs2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b1,
                        volume: oddData.bs1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "match_odds",
                        backLay: "back",
                        betFor: oddData.rname

                      }))}
                      color="darkblue" odd={oddData.b1} volume={oddData.bs1} />
                    <MarketBox

                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l1,
                        volume: oddData.ls1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "match_odds",
                        backLay: "lay",
                        betFor: oddData.rname

                      }))}
                      color="darkpink" odd={oddData.l1} volume={oddData.ls1} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l2,
                        volume: oddData.ls2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "match_odds",
                        backLay: "lay",
                        betFor: oddData.rname

                      }))}
                      color="lightpink" odd={oddData.l2} volume={oddData.ls2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l3,
                        volume: oddData.ls3,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "match_odds",
                        backLay: "lay",
                        betFor: oddData.rname

                      }))}
                      color="lighterpink" odd={oddData.l3} volume={oddData.ls3} />
                  </div>
                </div>
              })}

            </div>
          </div>
        })}
        {bookmaker?.map((item) => {
          return <div className="book_maker_main">
            <div className="black_header">
              <p>Bookmaker</p>
            </div>
            <div className="bookmaker_body">
              <div className="bookmaker_body_single">
                <div className="bookmaker_body_left">
                  <p>Min - 100 </p>
                  <p>Max - 1L</p>
                </div>
                <div className="bookmaker_body_right">
                  <div className="body_right "></div>
                  <div className="body_right "></div>
                  <div className="body_right darkblue">Back</div>
                  <div className="body_right darkpink">Lay</div>
                  <div className="body_right"></div>
                  <div className="body_right"></div>
                </div>
              </div>
              {item?.bm1?.oddDatas?.map((oddData) => {
                return <div className="bookmaker_body_single" key={oddData.sid}>
                  <div className="bookmaker_body_left">
                    <p>{oddData.rname}</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b3,
                        volume: oddData.bs3,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="lighterblue" odd={oddData.b3} volume={oddData.bs3} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b2,
                        volume: oddData.bs2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="lightblue" odd={oddData.b2} volume={oddData.bs2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b1,
                        volume: oddData.bs1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="darkblue" odd={oddData.b1} volume={oddData.bs1} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l1,
                        volume: oddData.ls1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))}

                      color="darkpink" odd={oddData.l1} volume={oddData.ls1} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l2,
                        volume: oddData.ls2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))}
                      color="lightpink" odd={oddData.l2} volume={oddData.ls2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l3,
                        volume: oddData.ls3,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "bookmaker",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))} color="lighterpink" odd={oddData.l3} volume={oddData.ls3} />

                  </div>
                </div>
              })}
              {/* <div className="bookmaker_body_single">
              <div className="bookmaker_body_left">
                <p>Gorakhpur Lions</p>
                <p>-1,000</p>
              </div>
              <div className="bookmaker_body_right">
                <div className="body_right lighterblue">-</div>
                <div className="body_right lightblue">-</div>
                <div
                  className="body_right darkblue"
                  onClick={() => {
                    openPlaceBet("blue");
                  }}
                >
                  76
                </div>
                <div
                  className="body_right darkpink"
                  onClick={() => {
                    openPlaceBet("pink");
                  }}
                >
                  80
                </div>
                <div className="body_right lightpink">-</div>
                <div className="body_right lighterpink"> -</div>
              </div>
            </div> */}
            </div>
          </div>
        })}

        {/* 4th normal div */}
        {fancyOdds.map((item) => {
          return <>
            <div className="black_header">
              <p>{item.gtype}</p>
            </div>
            <div className="normal_main_body">
              <div className="normal_main_left">
                <div className="normal_maker_main">
                  <div className="normal_body">
                    <div className="normal_body_single">
                      <div className="normal_body_left">
                        <p></p>
                      </div>
                      <div className="normal_body_right">
                        <div className="body_right darkblue">No</div>
                        <div className="body_right darkpink">Yes</div>
                        <div className="body_right"></div>
                      </div>
                    </div>

                    {item.oddDatas?.map((oddData) => {
                      return <div className="normal_body_single">
                        <div className="bookmaker_body_left">
                          <p>{oddData.rname}</p>
                        </div>
                        <div className="bookmaker_body_right">
                          <MarketBox
                            onClick={() => setBetDetail(new BetDetails({
                              marketId: `${gameDetails.eventId}_${oddData.sid}`,
                              odds: oddData.b1,
                              volume: oddData.bs1,
                              gameDetails: gameDetails,
                              sport: queryParams.type,
                              type: "fancy",
                              backLay: "back",
                              betFor: oddData.rname
                            }))} odd={oddData.b1} volume={oddData.bs1} color="darkblue" />
                          <MarketBox
                            onClick={() => setBetDetail(new BetDetails({
                              marketId: `${gameDetails.eventId}_${oddData.sid}`,
                              odds: oddData.l1,
                              volume: oddData.ls1,
                              gameDetails: gameDetails,
                              sport: queryParams.type,
                              type: "fancy",
                              backLay: "lay",
                              betFor: oddData.rname
                            }))}
                            odd={oddData.l1} volume={oddData.ls1} color="darkpink" />


                          <div className="body_right min_max_bet">
                            <p>Min 100</p>
                            <p>Max 2L</p>
                          </div>
                        </div>
                      </div>
                    })}

                  </div>
                </div>
              </div>
              {/* <div className="normal_main_right">
            <div className="book_maker_main">
              <div className="bookmaker_body">
                <div className="normal_body_single">
                  <div className="normal_body_left">
                    <p></p>
                  </div>
                  <div className="normal_body_right">
                    <div className="body_right darkblue">No</div>
                    <div className="body_right darkpink">Yes</div>
                    <div className="body_right"></div>
                  </div>
                </div>
                <div className="normal_body_single">
                  <div className="bookmaker_body_left">
                    <p>Lucknow Falcons</p>
                    <p>620</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div
                      className="body_right darkpink"
                      onClick={() => {
                        openPlaceBet("pink");
                      }}
                    >
                      80
                    </div>
                    <div className="body_right min_max_bet">
                      <p>Min 100</p>
                      <p>Max 2L</p>
                    </div>
                  </div>
                </div>
                <div className="normal_body_single">
                  <div className="">
                    <p>Gorakhpur Lions</p>
                    <p>-1,000</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div
                      className="body_right darkpink"
                      onClick={() => {
                        openPlaceBet("pink");
                      }}
                    >
                      80
                    </div>
                    <div className="body_right min_max_bet">
                      <p>Min 100</p>
                      <p>Max 2L</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </>
        })}
        {othermarket?.map((item) => {
          return <div className="book_maker_main" key={item.mid}>
            <div className="black_header">
              <p>{item.mname} </p>
            </div>
            <div className="bookmaker_body">
              <div className="bookmaker_body_single">
                <div className="bookmaker_body_left">
                  <p>Min - 100 </p>
                  <p>Max - 1L</p>
                </div>
                <div className="bookmaker_body_right">
                  <div className="body_right "></div>
                  <div className="body_right "></div>
                  <div className="body_right darkblue">Back</div>
                  <div className="body_right darkpink">Lay</div>
                  <div className="body_right"></div>
                  <div className="body_right"></div>
                </div>
              </div>
              {item.oddDatas.map((oddData) => {
                return <div className="bookmaker_body_single" key={oddData.sid}>
                  <div className="bookmaker_body_left">
                    <p>{oddData.rname}</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b3,
                        volume: oddData.bs3,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="lighterblue" odd={oddData.b3} volume={oddData.bs3} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b2,
                        volume: oddData.bs2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="lightblue" odd={oddData.b2} volume={oddData.bs2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.b1,
                        volume: oddData.bs1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "back",
                        betFor: oddData.rname
                      }))}
                      color="darkblue" odd={oddData.b1} volume={oddData.bs1} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l1,
                        volume: oddData.ls1,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))}
                      color="darkpink" odd={oddData.l1} volume={oddData.ls1} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l2,
                        volume: oddData.ls2,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))}
                      color="lightpink" odd={oddData.l2} volume={oddData.ls2} />
                    <MarketBox
                      onClick={() => setBetDetail(new BetDetails({
                        marketId: `${gameDetails.eventId}_${oddData.sid}`,
                        odds: oddData.l3,
                        volume: oddData.ls3,
                        gameDetails: gameDetails,
                        sport: queryParams.type,
                        type: "other_market",
                        backLay: "lay",
                        betFor: oddData.rname
                      }))}
                      color="lighterpink" odd={oddData.l3} volume={oddData.ls3} />
                  </div>
                </div>
              })}

            </div>
          </div>
        })}
        {/* <div className="black_header">
          <p>Over BY Over</p>
        </div>
        <div className="over_body_main">
          <div className="over_body_single">
            <div className="over_body_left">
              <p></p>
            </div>
            <div className="over_body_right">
              <div className="body_right darkblue">No</div>
              <div className="body_right darkpink">Yes</div>
              <div className="body_right"></div>
            </div>
          </div>

          <div className="over_body_single">
            <div className="over_body_left">
              <p>only 9 over</p>
            </div>
            <div className="over_body_right">
              <div
                className="body_right darkblue"
                onClick={() => {
                  openPlaceBet("blue");
                }}
              >
                76
              </div>
              <div
                className="body_right darkpink"
                onClick={() => {
                  openPlaceBet("pink");
                }}
              >
                80
              </div>
              <div className="body_right min_max_bet">
                <p>Min 100</p>
                <p>Max 2L</p>
              </div>
            </div>
          </div>
        </div>
        <div className="black_header">
          <p>Odd even</p>
        </div>

        <div className="normal_main_body">
          <div className="normal_main_left">
            <div className="normal_maker_main">
              <div className="normal_body">
                <div className="normal_body_single">
                  <div className="bookmaker_body_left">
                    <p>15 over run GL</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div className="body_right min_max_bet">
                      <p>Min 100</p>
                      <p>Max 2L</p>
                    </div>
                  </div>
                </div>
                <div className="normal_body_single">
                  <div className="bookmaker_body_left">
                    <p>11 over run GL</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div className="body_right min_max_bet">
                      <p>Min 100</p>
                      <p>Max 2L</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="normal_main_right">
            <div className="book_maker_main">
              <div className="bookmaker_body">
                <div className="normal_body_single">
                  <div className="bookmaker_body_left">
                    <p>Lucknow Falcons</p>
                    <p>620</p>
                  </div>
                  <div className="bookmaker_body_right">
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div
                      className="body_right darkblue"
                      onClick={() => {
                        openPlaceBet("blue");
                      }}
                    >
                      76
                    </div>
                    <div className="body_right min_max_bet">
                      <p>Min 100</p>
                      <p>Max 2L</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="black_header">
          <p>2Nd ii nn</p>
        </div>
        <div className="four_body">
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">76</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">76</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">76</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">76</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">76</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">80</div>
            </div>
          </div>
          <div className="four_body_single">
            <div className="four_body_left">
              <p>Lucknow Falcons</p>
              <p>620</p>
            </div>
            <div className="four_body_right">
              <div className="body_right darkblue">80</div>
            </div>
          </div>
        </div> */}
      </div>

      {/* total right section */}
      <div className="game_details_main_right">
        <div className="mive_match_main">
          <div
            className="black_header live_match"
            onClick={() => setShowVideo(!showVideo)}
          >
            Live Match
          </div>
          {showVideo && (
            <div>
              <iframe
                width="336"
                height="315"
                src="https://www.youtube.com/embed/tmg6d3T_T6Q"
                title="DiamondEXCH99"
              ></iframe>
            </div>
          )}
        </div>
        {betDetail && (
          <>
            <div className="place_bet_main">
              <div className="black_header place_bet">Place Bet</div>
              <div className="place_bet_body">
                <div className="place_bet_head">
                  <div className="place_header">
                    <p>(Bet for)</p>
                    <p>Odds</p>
                  </div>
                  <div className="place_header">
                    <p>Stake</p>
                    <p>Profit</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mybet_main">
              {/* <div className="mybet_bluediv"> */}
              <div
                className={`${betDetail.backLay === "back" ? "mybet_bluediv" : ""} ${betDetail.backLay === "lay" ? "mybet_pinkdiv" : ""
                  }`}
              >
                {" "}
                <div className="mybet_bluediv_up">
                  <div className="bet_for_game_name">
                    <p>{betDetail?.betFor}</p>
                  </div>
                  <div class="placebet_input_main">
                    <div className="left_with_arrow">
                      <input
                        type="number"
                        class="placebet_input"
                        id="exampleFormControlInput1"
                        value={betDetail.odds}
                      />
                      <div class="spinner-buttons input-group-btn btn-group-vertical">
                        <button class=" up_arow">
                          <i class="fa fa-angle-up"></i>
                        </button>
                        <button class=" up_arow">
                          <i class="fa fa-angle-down"></i>
                        </button>
                      </div>
                    </div>
                    <input
                      type="number"
                      class="placebet_input"
                      id="exampleFormControlInput1"
                      min={0}
                      value={betDetail.stake}
                      onChange={(e)=>{
                        setBetDetail((prev)=>({...prev,stake:parseInt(e.target.value)}))}}
                    />
                  </div>

                  <div>
                    <p>{getProfit}</p>
                  </div>
                </div>
                <div className="place_button">
                  {betButtonsData &&
                    betButtonsData?.bettingButtons?.map((item) => {
                      return (
                        <button onClick={() => setBetDetail((prev) => ({ ...prev, stake: item.price }))} className="btn btn-light">{item.tag}</button>
                      );
                    })}
                </div>
                <div className="mybet_bluediv_buttom">
                  <div>
                    <button
                      type="button"
                      class="btn btn-info"
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div>
                    <button type="button" class="btn btn-danger">
                      Reset
                    </button>
                    <button onClick={submitBet} type="button" class="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="black_header mybet">My Bet</div>
        <div className="my_bet_head">
          <p>Matched bet</p>
          <p>Odds</p>
          <p>Stake</p>
        </div>
        {betPlaced.filter(item => item.eventId === queryParams.id).map((item) => {
          return <div className={`my_bet_head ${item.backLay === "back" ? "mybet_bluediv" : ""} ${item.backLay === "lay" ? "mybet_pinkdiv" : ""}`}>
            <p>{item.betFor}</p>
            <p>{item.odds}</p>
            <p>{item.stake}</p>
          </div>
        })}
      </div>
      {showModal && (
        <EditButtonModal
          setShowModal={setShowModal}
          buttonsData={betButtonsData}
        />
      )}
    </div>
  );
};

export default GameDetails;
