import React, { useEffect, useState } from "react";
import modalImg from "../images/modalImg.png";
import "./Modal.css";
import { requestHandler } from "../utils/requestHandler";
import { COMMON_URL } from "../utils/constants";

const HomePageModal = () => {
  const [showModal, setShowModal] = useState(true);
  const [banner,setBanner] = useState("")

  const getBanner = async () => {
    let res = await requestHandler({
      endpoint: "/user/banner",
      method: "GET",
    }).then(res=>{
      setBanner(res.data.bannerImagePath)
    })
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <div>
      {showModal && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <div className="custom-modal-header">
              <i
                className="close-icon"
                onClick={() => setShowModal(false)}
                style={{ cursor: "pointer" }}
              >
                &times;
              </i>
            </div>
            <div className="custom-modal-body">
              {/* <img src={modalImg} alt="Modal" className="modal-image" /> */}
              <img src={`${COMMON_URL}/${banner}`} alt="Uploaded Image" className="modal-image"/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePageModal;
