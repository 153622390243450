import React, { useState, useEffect, useCallback } from "react";
import GameDataTable from "../../components/GameDataTable";
import HomePageModal from "../../modal/HomePageModal";

import { io } from "socket.io-client";
import { api } from "../../utils/requestHandler";
import { useSearchParams } from "react-router-dom";
const SOCKET_URL = "http://134.209.148.155:5000";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Link1");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();




  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };





  useEffect(() => {
    // fetchCricketMatchList();
  }, [socket])



  const matchList = useCallback(async () => {
    let sport = searchParams.get("sport")
    if(!sport||sport==="cricket"){
      try {
        let res = await api.get(`/cricket/matches`)
        // console.log((await res).data);
        setData(res.data.data)
  
      } catch (error) {
        console.log("error in match list")
      }
    }
    if(sport==="soccer"){
      try {
        let res = await api.get(`/soccer/matches`)
        // console.log((await res).data);
        setData(res.data.data)
  
      } catch (error) {
        console.log("error in match list")
      }
    }
    if(sport==="tennis"){
      try {
        let res = await api.get(`/tennis/matches`)
        // console.log((await res).data);
        setData(res.data.data)
  
      } catch (error) {
        console.log("error in match list")
      }
    }
  

  }, [searchParams])

  useEffect(() => {
    // Initial fetch
    matchList();

    // Polling every 5 seconds (5000 milliseconds)
    const intervalId = setInterval(() => {
      matchList();
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [searchParams]);

  return (
    <>
      <HomePageModal />
      <div className="home_container">
        <div className="home_second_container">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <p
                className={`nav-link ${searchParams.get("sport") === "cricket" ? "active-tab" : ""
                  }`}
                onClick={() => {
                  handleTabClick("Link1");
                  setSearchParams({ sport: "cricket" })

                  // fetchCricketMatchList();
                }}
              >
                Cricket
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${searchParams.get("sport") === "soccer" ? "active-tab" : ""
                  }`}
                onClick={() => {
                  handleTabClick("Link2");
                  setSearchParams({ sport: "soccer" })
                  // fetchSoccerMatchList();
                }}
              >
                Soccer
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${searchParams.get("sport") === "tennis" ? "active-tab" : ""
                  }`}
                onClick={() => {
                  handleTabClick("Disabled");
                  setSearchParams({ sport: "tennis" })

                  // fetchTennisMatchList();
                }}
              >
                Tennis
              </p>
            </li>
          </ul>
        </div>
        <div className="home_body">
          <GameDataTable data={data} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
