export const matchoddsBackProfit = (odds, stake) => {
    return (odds * stake) - stake
}
export const matchoddsBackLoss = (odds, stake) => {
    return stake 
}
export const matchoddsLayProfit = (odds, stake) => {
    return stake
}
export const matchoddsLayLoss = (odds, stake) => {
    return (odds * stake) - stake
}
export const bookmakeBackProfit = (odds, stake) => {
    return (odds/100)*stake
}
export const bookmakeBackLoss = (odds, stake) => {
    return stake
}
export const bookmakeLayProfit = (odds, stake) => {
    return stake
}
export const bookmakeLayLoss = (odds, stake) => {
    return (odds/100)*stake
}
export const fancyYesProfit = (volume,stake)=>{
        return (volume/100)*stake
}
export const fancyYesLoss = (volume,stake)=>{
    return stake
}
export const fancyNoProfit = (volume,stake)=>{
    return stake
}
export const fancyNoLoss = (volume,stake)=>{
    return (volume/100)*stake
}

