import React from 'react'

function Casino() {
  return (
    <div>
        <iframe src='https://video.proexch.in/tv/static?chid=340'/>
    </div>
  )
}

export default Casino