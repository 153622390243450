import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import gameIcon from "../images/icon/game-icon.svg";
import { io } from 'socket.io-client';
import MarketBox from './MarketBox';
import { useSearchParams } from 'react-router-dom';
const SOCKET_URL = "http://134.209.148.155:5000";


function MatchDetail({ match }) {
    const navigate = useNavigate()
    const [matchOdds, setMatchOdds] = useState([])
    const [searchParams]= useSearchParams()
    const type = searchParams.get("sport")?searchParams.get("sport"):"cricket"
    useEffect(() => {
        if(type==="cricket"){
            if(match?.detail?.matchOdds && Array.isArray(match?.detail?.matchOdds[0]?.oddDatas)){
                setMatchOdds(match.detail?.matchOdds[0]?.oddDatas)
               }
        }
        if(type==="soccer"){
            if(match?.detail?.matchOddsResponseDTO && Array.isArray(match?.detail?.matchOddsResponseDTO[0]?.oddDatas)){
                setMatchOdds(match.detail?.matchOddsResponseDTO[0]?.oddDatas)
               }
        }
        if(type==="tennis"){
            if(match?.detail?.matchOddsResponseDTO && Array.isArray(match?.detail?.matchOddsResponseDTO[0]?.oddDatas)){
                setMatchOdds(match.detail?.matchOddsResponseDTO[0]?.oddDatas)
               }
        }

    //    setMatchOdds([])
    }, [match])
    
    return (
        <div className="headBody">
            <div className="game_left">
                <div>
                    <h4
                        className="game_name"
                        onClick={() => {
                            navigate(`/gamedetails/${type}/${match?.eventId}`);
                        }}
                    >
                        {match?.eventName} /{match.eventTime}
                    </h4>
                </div>
                <div className="game_left_right">
                    <div className="icons_main_div">
                        {match.green ? <div className="green_circle"></div> : ""}
                    </div>
                    <div className="icons_main_div">
                        {match.live ? (
                            <div>
                                <i class="fas fa-tv icon-tv"></i>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="icons_main_div">
                        {match.game ? (
                            <div>
                                <img
                                    src={gameIcon}
                                    alt="gameIcon"
                                    className="gameIcon"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="game_right">
                <MarketBox color='bg_blue' odd={matchOdds[0]?.b1||"-"}/>
                <MarketBox color='bg_pink' odd={matchOdds[0]?.l1||"-"}/>
                <MarketBox color='bg_blue' odd={matchOdds[2]?.b1||"-"}/>
                <MarketBox color='bg_pink' odd={matchOdds[2]?.l1||"-"}/>
                <MarketBox color='bg_blue' odd={matchOdds[1]?.b1||"-"}/>
                <MarketBox color='bg_pink' odd={matchOdds[1]?.l1||"-"}/>

                {/* <div className="bg_blue">{matchOdds[0]?.b1||"-"}</div> */}
                {/* <div className="bg_pink">{matchOdds[0]?.l1||"-"}</div>
                <div className="bg_blue">{matchOdds[2]?.b1||"-"}</div>
                <div className="bg_pink">{matchOdds[2]?.l1||"-"}</div>
                <div className="bg_blue">{matchOdds[1]?.b1||"-"}</div>
                <div className="bg_pink">{matchOdds[1]?.l1||"-"}</div> */}
            </div>
        </div>)
}

export default MatchDetail