import React, {
    createContext,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { api, requestHandler } from '../utils/requestHandler';
import storage from '../utils/storage';
import { GET_ACCESS_TOKEN } from '../utils/ApiEndPoint';

const AuthContext = createContext();

export const useAuth = () => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return authContext;
};

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState();
    const [walletData,setWalletData] = useState(null)
    const [details,setDetails] = useState(null)
    const [betPlaced,setBetPlaced] = useState([])

    const fetchMe = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        requestHandler({
            endpoint:"/user/profile",
            method:"GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
        
              }
        }).then((res)=>{
            setBetPlaced(res.data.betPlaced)
            setDetails(res.data.details)
            setWalletData(res.data.walletDetails)

        }).catch(err=>{
            localStorage.clear()
            window.location.reload()
        })
    //   try {
    //     const response = await api.get('/user/profile');
    //     if (response.data.status) {
    //         setBetPlaced()
    //     //   setToken(response.data?.data?.accessToken);
    //     }
    //   } catch (error) {
    //     setToken(null);
    //   }
    };

    useEffect(() => {
      fetchMe();
    }, []);

    // useLayoutEffect(() => {
    //   const authInterceptor = api.interceptors.request.use((config) => {
    //     config.headers.Authorization =
    //       !(config)._retry && token
    //         ? `Bearer ${token}`
    //         : config.headers.Authorization;
    //     return config;
    //   });

    //   return () => {
    //     api.interceptors.request.eject(authInterceptor);
    //   };
    // }, [token]);

    useLayoutEffect(() => {
        const token = localStorage.getItem('token');
        // console.log("token", token);

        if (!token) return;

        const refreshInterceptor = api.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response.status === 401) {
                    localStorage.clear()
                    window.location.reload()

                    // originalRequest.headers.Authorization = `Bearer ${token}`;
                    // originalRequest._retry = true;
                    // return api(originalRequest);

                }
                return Promise.reject(error);
            }
        );
        return () => {
            api.interceptors.response.eject(refreshInterceptor);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ token, setToken,betPlaced,details,walletData,fetchMe }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
