export class BetDetails {
    constructor({ marketId, odds, volume, gameDetails, sport = "cricket", type = "match_odds", backLay = "back",betFor }) {
      this.marketId = marketId;
      this.eventId = gameDetails.eventId;
      this.sport = sport;
      this.type = type;
      this.remark = gameDetails.eventName;
      this.volume = volume;
      this.stake = 0;
      this.odds = odds;
      this.betFor = betFor;
      this.backLay = backLay;
    }
  }
  
  export const BET_TYPE= {
    MATCH_ODDS:"match_odds",
    BOOKMAKER:"bookmaker"
  }